import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"; // Import the CSS file for additional styles
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

import Navbar from "./components/Navbar";
import SingleResult from "./pages/SingleResult";
import MultiResult from "./pages/MultiResult";

const App = () => {
  return (
    <Router>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <Navbar />
        <Routes>
          <Route path="/" element={<SingleResult />} />
          <Route path="/multi" element={<MultiResult />} />
        </Routes>

        <Analytics />
        <SpeedInsights />
      </div>
    </Router>
  );
};

export default App;
