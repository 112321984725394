import React, { useState, useEffect } from "react";
import SearchBar from "../components/SearchBar";
import GoogleMapsComponent from "../components/GoogleMapsComponent";
import ResultsPanel from "../components/ResultsPanel";
import "./css/MultiResult.css";

// TO-DO: Make the layout look better on mobile

function MultiResult() {
  const [places, setPlaces] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [showDirections, setShowDirections] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 35.6762, lng: 139.6503 });
  const [mapZoom, setMapZoom] = useState(13);

  const handleSearch = (searchParams) => {
    // Simulate fetching new data based on searchParams
    console.log(searchParams);

    // TO-DO: Copy the code from SingleResult to send items to the backend

    const backendResponse = [
      {
        name: "Sushi Place",
        type: "sushi",
        distance: 300,
        coordinates: [139.6503, 35.6762],
      },
      {
        name: "Cafe",
        type: "cafe",
        distance: 400,
        coordinates: [139.7023, 35.6703],
      },
    ];

    setPlaces(backendResponse);
    setCoordinates([
      { name: "Sushi Place", type: "sushi", coordinates: [139.6503, 35.6762] },
      { name: "Cafe", type: "cafe", coordinates: [139.7023, 35.6703] },
    ]);

    // Resetting directions response to trigger re-render
    setShowDirections(false); // Hide directions initially
    setTimeout(() => {
      setShowDirections(true); // Show directions after the new coordinates are set
    }, 0);
  };

  const handlePlaceClick = (idx) => {
    // Center the map on the clicked place
    const selectedPlace = coordinates[idx];
    setMapCenter({
      lat: selectedPlace.coordinates[1],
      lng: selectedPlace.coordinates[0],
    });
    setMapZoom(15); // Zoom in when a place is clicked
  };

  return (
    <div>
      <SearchBar onSearch={handleSearch} />
      <div id="main-container">
        <ResultsPanel places={places} onPlaceClick={handlePlaceClick} />
        <GoogleMapsComponent
          coordinates={coordinates}
          showDirections={showDirections}
          mapCenter={mapCenter} // Pass mapCenter as a prop
          mapZoom={mapZoom} // Pass mapZoom as a prop
        />
      </div>
    </div>
  );
}

export default MultiResult;
