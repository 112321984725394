import React from "react";
import "./css/ResultsPanel.css";

const ResultsPanel = ({ places, onPlaceClick }) => {
  return (
    <div id="results-panel">
      <ul className="place-card-container">
        {places.map((place, idx) => (
          <li
            key={idx}
            className="place-card"
            onClick={() => onPlaceClick(idx)}
          >
            <h3>{place.name}</h3>
            <p>Type: {place.type}</p>
            <p>Distance: {place.distance.toFixed(1)} m</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ResultsPanel;
