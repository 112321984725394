import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

// TO-DO: offload the tracking code to a component and import it here

const SingleResult = () => {
  const [location, setLocation] = useState("Tokyo");
  const [typeOfFood, setTypeOfFood] = useState("Sushi");
  const [restaurant, setRestaurant] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false); // Tooltip visibility
  const [isTracking, setIsTracking] = useState(true); // Location tracking state (on by default)
  const [firstLocationRequest, setFirstLocationRequest] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const dropdownRef = useRef(null);

  // Placeholder images array
  const [imageArray, setImageArray] = useState([]);
  const [attributionNameArray, setAttributionNameArray] = useState([]);
  const [attributionSiteArray, setAttributionSiteArray] = useState([]);

  // Function to go to the next image
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  };

  // Function to go to the previous image
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageArray.length) % imageArray.length
    );
  };

  // const [adLoaded, setAdLoaded] = useState(false); // Track if ad has been loaded
  const [locationMessage, setLocationMessage] = useState(
    "Getting your location..."
  );
  const [loading, setLoading] = useState(false); // New loading state

  let watchId; // Declare watchId
  let retryInterval; // Declare retryInterval
  let errorTimeout; // New variable to manage error cooldown

  const [geoLocation, setGeoLocation] = useState({
    latitude: null,
    longitude: null,
  }); // Store location

  const handleSuccess = (position) => {
    if (!isTracking) return; // Don't update if tracking is disabled

    const { latitude, longitude } = position.coords;
    setGeoLocation({ latitude, longitude });
    setLocationMessage("You can now find the best eats near you!");

    // Clear any existing retry intervals since we got the location
    clearInterval(retryInterval);
  };

  const handleError = (error) => {
    if (!isTracking) return; // Check if tracking is still active before handling error

    // Prevent stacking errors by clearing the previous timeout
    clearTimeout(errorTimeout);

    console.error("Error getting location:", error);
    setLocationMessage(
      "Failed to get your location. Retrying in a few seconds... Please check your browser location permissions!"
    );

    // Retry only if tracking is still enabled
    errorTimeout = setTimeout(() => {
      if (isTracking) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
      }
    }, 3000); // Retry every 3 seconds after an error
  };

  const toggleTracking = () => {
    setIsTracking((prevTracking) => {
      const newTrackingState = !prevTracking; // Determine new tracking state

      if (newTrackingState) {
        // When enabling tracking
        setGeoLocation({ latitude: null, longitude: null }); // Reset location
        setLocationMessage("Getting your location..."); // Show getting location message
      } else {
        // When disabling tracking
        clearInterval(retryInterval); // Clear the retry interval
        setLocationMessage(""); // Optionally clear message when tracking is off
        setGeoLocation({ latitude: null, longitude: null }); // Clear location
      }

      return newTrackingState; // Return the new tracking state
    });
  };

  useEffect(() => {
    if (isTracking) {
      // Check if it's the first attempt to get the location
      if (firstLocationRequest) {
        setLocationMessage("Getting your location..."); // Show message only on the first attempt
        setFirstLocationRequest(false); // Set to false after the first request
      }

      // Start tracking geolocation
      watchId = navigator.geolocation.watchPosition(
        handleSuccess,
        handleError,
        {
          enableHighAccuracy: true,
          timeout: 25000,
          maximumAge: 0,
        }
      );

      // Automatically refresh geolocation every 3 seconds
      retryInterval = setInterval(() => {
        if (isTracking) {
          // Check if tracking is still enabled before retrying
          navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        }
      }, 3000);

      return () => {
        // Clean up both the geolocation watch and interval when unmounting or stopping tracking
        navigator.geolocation.clearWatch(watchId);
        clearInterval(retryInterval); // Clear the refresh interval
        clearTimeout(errorTimeout); // Clear any pending retry timeouts
      };
    } else {
      // If tracking is disabled, clear location
      setGeoLocation({ latitude: null, longitude: null });
      setLocationMessage(null); // Reset location message
      setFirstLocationRequest(true); // Reset to allow message on next enabling
    }
  }, [isTracking]);

  // Preload all images when component mounts
  useEffect(() => {
    imageArray.forEach((src) => {
      const img = new Image();
      img.src = src; // Preload the image
    });
  }, [imageArray]);

  // Adjust width dynamically based on selected option
  useEffect(() => {
    const adjustWidth = () => {
      if (dropdownRef.current) {
        const tempElement = document.createElement("span");
        tempElement.style.visibility = "hidden";
        tempElement.style.fontSize = window.getComputedStyle(
          dropdownRef.current
        ).fontSize;
        tempElement.style.fontWeight = window.getComputedStyle(
          dropdownRef.current
        ).fontWeight;
        tempElement.textContent = typeOfFood;

        document.body.appendChild(tempElement);
        dropdownRef.current.style.width = `${tempElement.offsetWidth + 60}px`; // +20px for padding
        document.body.removeChild(tempElement);
      }
    };

    adjustWidth(); // Adjust width on initial render and when option changes
  }, [typeOfFood]);

  // Function to handle tooltip hover
  const handleMouseOver = () => {
    setTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setTooltipVisible(false);
  };

  // Function to handle tooltip visibility on click
  const handleTooltipClick = () => {
    setTooltipVisible((prev) => !prev);
  };

  // REMEMBER TO REMOVE THE TEST BEFORE PROD DEPLOYMENT!
  const api_url = process.env.REACT_APP_BACKEND_URL;

  const handleRandomRestaurant = async () => {
    // Clear the image array
    setImageArray([]);
    setAttributionNameArray([]);
    setAttributionSiteArray([]);
    setLoading(true); // Set loading state to true
    try {
      let url = `${api_url}restaurants?location=${location}&type_of_food=${typeOfFood}`;

      // If user has allowed access to location, add their lat/lng to the request
      if (geoLocation.latitude && geoLocation.longitude) {
        url += `&lat=${geoLocation.latitude}&lng=${geoLocation.longitude}`;
      }

      // Set your API key (you can fetch it from your environment variables)
      const apiKey = process.env.REACT_APP_API_KEY; // Ensure you set this in your .env file

      // Make the GET request with the x-api-key header
      const response = await axios.get(url, {
        headers: {
          "x-api-key": apiKey, // Include the API key here
        },
      });

      let imgArray = [];
      let attrNameArray = [];
      let attrSiteArray = [];

      //Populate the image data arrays
      try {
        for (let x = 0; x < response.data.photo_data.length; x++) {
          imgArray.push(response.data.photo_data[x].photo_url);
          attrNameArray.push(response.data.photo_data[x].html_attr_name);
          attrSiteArray.push(response.data.photo_data[x].html_attr_address);
        }
        setImageArray(imgArray);
        setAttributionNameArray(attrNameArray);
        setAttributionSiteArray(attrSiteArray);
      } catch {
        console.log("No images yet for this restaurant");
      }

      setRestaurant(response.data);
    } catch (error) {
      console.error("Error fetching restaurant:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching
    }
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        {/* Geolocation toggle switch */}
        <div className="location-tracking">
          <label>
            Find food near me
            <label className="slider">
              <input
                type="checkbox"
                checked={isTracking}
                onChange={toggleTracking}
              />
              <span className="slider-round"></span>
            </label>
          </label>
          {/* Tooltip Icon */}
          <div
            onClick={handleTooltipClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <span
              className="tooltip-icon"
              style={{ zIndex: 100 }} // Ensure the icon is on top
            >
              ℹ️
              {tooltipVisible && (
                <div className="tooltip" onClick={(e) => e.stopPropagation()}>
                  Geolocation data is not stored, only used to determine the
                  closest restaurants.
                </div>
              )}
            </span>
          </div>
        </div>

        <div className="location-info">
          <p>{locationMessage}</p>
        </div>
      </div>

      <h2 className="headline">
        I want to eat the best{" "}
        <select
          value={typeOfFood}
          onChange={(e) => setTypeOfFood(e.target.value)}
          className="dropdown-select"
          ref={dropdownRef}
        >
          <option value="Sushi">Sushi 🍣</option>
          <option value="Soba">Soba 🍜</option>
          <option value="Udon">Udon 🍜</option>
          <option value="Ramen">Ramen 🍜</option>
          <option value="Dessert">Dessert 🍰</option>
        </select>{" "}
        {isTracking ? "near me" : "in"}{" "}
        {!isTracking && (
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="dropdown-select"
          >
            <option value="Tokyo">Tokyo</option>
            <option value="Kyoto">Kyoto</option>
            <option value="Osaka">Osaka</option>
          </select>
        )}
        !
      </h2>
      <button onClick={handleRandomRestaurant} className="find-restaurant-btn">
        Find it for me!
      </button>

      {loading ? (
        <div className="loading-container">
          <img
            src="/bongo_cat_loading.gif" // Replace with your loading GIF path
            alt="Loading..."
            className="loading-gif"
          />
          <p>
            He's finding you some good {typeOfFood.toLowerCase()}
            <span className="loading-dots">...</span>
          </p>
        </div>
      ) : (
        restaurant && (
          <div className="container">
            <div className="restaurant-card">
              <div className="restaurant-details">
                <h2>{restaurant.name}</h2>
                <p>{restaurant.formatted_address}</p>
                <p>
                  Rating: {restaurant.rating} <span>⭐</span> |{" "}
                  {restaurant.user_ratings_total} reviews
                </p>

                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    restaurant.formatted_address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="map-link"
                >
                  <button className="find-restaurant-btn">
                    Get me there now!
                  </button>
                </a>
              </div>
              <div className="carousel-container">
                <div className="carousel">
                  {/* Display the current image */}
                  <img
                    src={imageArray[currentIndex]}
                    alt={`Restaurant Photo ${currentIndex + 1}`}
                    className="carousel-image"
                  />
                </div>

                {/* Carousel navigation buttons */}
                <button className="carousel-prev" onClick={prevSlide}>
                  &#10094;
                </button>
                <button className="carousel-next" onClick={nextSlide}>
                  &#10095;
                </button>
              </div>
              <p>
                photo by{" "}
                <a href={attributionSiteArray[currentIndex]} target="_blank">
                  {attributionNameArray[currentIndex]}
                </a>
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default SingleResult;
