import React, { useState } from "react";
import "./css/SearchBar.css";

// TO-DO: If the placeTypes contains three items, disable the dropdown

const SearchBar = ({ onSearch }) => {
  const [placeTypes, setPlaceTypes] = useState([]);
  const [radius, setRadius] = useState(500);

  const handleTypeChange = (e) => {
    const selectedValue = e.target.value;

    // Add the selected value to the placeTypes array
    setPlaceTypes((prevTypes) => [...prevTypes, selectedValue]);
  };

  const handleRemoveType = (index) => {
    // Remove the item at the specified index
    setPlaceTypes((prevTypes) => prevTypes.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(placeTypes);
    console.log(radius);

    if (placeTypes.length > 0) {
      // Pass search parameters to the parent (MultiResult.js)
      onSearch({ placeTypes, radius });
    } else {
      alert("Please select at least one place type.");
    }
  };

  return (
    <form onSubmit={handleSubmit} id="search-bar">
      <div className="form-group">
        <label htmlFor="place-types">Select Place Types:</label>
        <select id="place-types" onChange={handleTypeChange}>
          <option value="">-- Select a place type --</option>
          <option value="Sushi">Sushi</option>
          <option value="Udon">Udon</option>
          <option value="Soba">Soba</option>
          <option value="Ramen">Ramen</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="radius">Search Radius (m): {radius}m</label>
        <input
          type="range"
          id="radius"
          min="100"
          max="2000"
          value={radius}
          onChange={(e) => setRadius(e.target.value)}
        />
      </div>

      <div className="selected-types">
        <h4>Selected Types:</h4>
        <ul>
          {placeTypes.map((type, index) => (
            <li key={index}>
              {type}
              <button type="button" onClick={() => handleRemoveType(index)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      <button type="submit" className="find-group-btn">
        Find Places
      </button>
    </form>
  );
};

export default SearchBar;
