import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";

// Adjust map style from here
const mapContainerStyle = {
  width: "100%",
  height: "100vh",
};

const defaultPosition = { lat: 35.6762, lng: 139.6503 }; // Tokyo, Japan

const GoogleMapsComponent = ({
  coordinates = [],
  showDirections,
  mapCenter,
  mapZoom,
}) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const mapRef = useRef(null);

  const onMapClick = useCallback(() => {
    setSelectedPlace(null);
  }, []);

  const directionsCallback = useCallback((result, status) => {
    if (status === "OK") {
      setDirectionsResponse(result);
    } else {
      console.error(`Error fetching directions: ${status}`);
    }
  }, []);

  useEffect(() => {
    if (coordinates.length > 0) {
      // Create bounds for the markers
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((place) => {
        bounds.extend({
          lat: place.coordinates[1],
          lng: place.coordinates[0],
        });
      });

      // Set the map's bounds and zoom level on the first load
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [coordinates]);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={mapCenter}
        zoom={mapZoom}
        onClick={onMapClick}
        onLoad={(map) => (mapRef.current = map)}
        options={{
          disableDefaultUI: true,
          gestureHandling: "greedy", // Allows zoom and panning
        }}
      >
        {coordinates.map((place, idx) => (
          <Marker
            key={idx}
            position={{
              lat: place.coordinates[1],
              lng: place.coordinates[0],
            }}
            label={String.fromCharCode(65 + idx)}
            onClick={() => setSelectedPlace(place)}
          />
        ))}

        {selectedPlace && (
          <InfoWindow
            position={{
              lat: selectedPlace.coordinates[1],
              lng: selectedPlace.coordinates[0],
            }}
            onCloseClick={() => setSelectedPlace(null)}
          >
            <div>
              <strong>{selectedPlace.name}</strong>
              <br />
              Type: {selectedPlace.type}
            </div>
          </InfoWindow>
        )}

        {showDirections && coordinates.length > 1 && (
          <>
            <DirectionsService
              options={{
                origin: {
                  lat: coordinates[0].coordinates[1],
                  lng: coordinates[0].coordinates[0],
                },
                destination: {
                  lat: coordinates[1].coordinates[1],
                  lng: coordinates[1].coordinates[0],
                },
                travelMode: "WALKING",
              }}
              callback={directionsCallback}
            />
            {directionsResponse && (
              <DirectionsRenderer
                options={{
                  directions: directionsResponse,
                  suppressMarkers: true,
                  polylineOptions: {
                    strokeColor: "#0000FF",
                    strokeWeight: 5,
                  },
                  preserveViewport: true,
                }}
              />
            )}
          </>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapsComponent;
