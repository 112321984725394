// src/Navbar.js
import React from "react";
import { Link } from "react-router-dom"; // Import Link for navigation

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <img
          src={`${process.env.PUBLIC_URL}/hij_logo_main.png`}
          alt="App Logo"
          className="main-logo"
        />
      </div>
    </nav>
  );
};

export default Navbar;
